/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var AP7 = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        AP7.addFeatureClasses();
        AP7.documentList();
        AP7.resize();
        if (mq('(min-width: 992px)')) {

          if(jQuery('.ap7__sideBar').length > 0){
            AP7.stickySideBar();
            AP7.stickyArchiveHeader();
          }
          AP7.stickyNav();

        } else {
          AP7.stickyMobileNav();
          AP7.mobileNavButton();
          AP7.mobileNavMore();
        }
        AP7.searchButton();
        AP7.distributionSlider();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        // AP7.heroChart();
        AP7.newHeroChart.init();
        window.addEventListener('resize', _.debounce(function() {
          AP7.newHeroChart.render();
        }, 150));

        AP7.filterList();
        AP7.videoHandler();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'page_template_template_aktiefond': {
      init: function() {
        if (typeof ap7_aktiefond_data !== 'undefined') {
          // AP7.pieChart(ap7_aktiefond_data);
          AP7.newPieChart.init(ap7_aktiefond_data);
          window.addEventListener('resize', _.debounce(function() {
            AP7.newPieChart.render();
          }, 150));
        }

        AP7.fundList();
      }
    },

    'page_template_template_aktiefond_eng': {
      init: function() { 
        AP7['page_template_template_aktiefond'].init();
      }
    },

    'page_template_template_rantefond': {
      init: function() {
        if (typeof ap7_rantefond_data !== 'undefined') {
          // AP7.pieChart(ap7_rantefond_data);
          AP7.newPieChart.init(ap7_rantefond_data);
          window.addEventListener('resize', _.debounce(function() {
            AP7.newPieChart.render();
          }, 150));
        }

        AP7.fundList();
      }
    },

    'page_template_template_rantefond_eng': {
      init: function() {
        AP7['page_template_template_rantefond'].init();
      }
    }
  };




  // AP7.getSize = function() {
  //     var w=window,
  //     d=document,
  //     e=d.documentElement,
  //     g=d.getElementsByTagName('body')[0],
  //     x=w.innerWidth||e.clientWidth||g.clientWidth,
  //     y=w.innerHeight||e.clientHeight||g.clientHeight;

  //     return {
  //       width: x,
  //       height: y
  //     };
  // };

  var mq = (function() {
    var matchMedia = window.matchMedia || window.msMatchMedia;
    if (matchMedia) {
      return function(mq) {
        var mql = matchMedia(mq);
        return mql && mql.matches || false;
      };
    }

    return function(mq) {
      var bool = false;

      injectElementWithStyles('@media ' + mq + ' { #modernizr { position: absolute; } }', function(node) {
        bool = (window.getComputedStyle ?
                window.getComputedStyle(node, null) :
                node.currentStyle).position == 'absolute';
      });

      return bool;
    };
  })();




/* hero chart */


  AP7.newHeroChart = (function() {
    var containerWidth,
        containerHeight,
        margin = {},
        width,
        height,
        svg,
        g,
        x,
        xAxis,
        y,
        z,
        parseTime,
        line,
        linesData,
        data,
        gridLines,
        funds,
        newFunds,
        overlay,
        bisectDate;

    function type (d, _, columns) {
      d.date = parseTime(d.date);
      for (var i = 1, n = columns.length, c; i < n; ++i) {
        d[c = columns[i]] = +d[c];
      }
      
      return d;
    }

    function mousemove() {
      
      var x0 = x.invert(d3.mouse(this)[0]),
          i = bisectDate(data, x0, 1),
          // d0 = data[i - 1],
          d0 = data[i],
          d1 = data[i],
          d = x0 - d0.year > d1.year - x0 ? d1 : d0;

      var monthNames = ["Januari", "Februari", "Mars", "April", "Maj", "Juni",
        "Juli", "Augusti", "September", "Oktober", "November", "December"];

      var dd = d.date,
          month = '' + (monthNames[dd.getMonth()]),
          day = '' + dd.getDate(),
          year = dd.getFullYear();

      if (day.length < 2) day = '0' + day;

      var newDate = [day, month, year].join(' ');

      _.each(d3.keys(d), function(key) {

        if (key !== 'date') {

          d3.select('.tooltip.' + key)
            .style('display', 'block')
            .transition()
            .duration(50)
            .attr('transform', function(da) {
              var xPos = x(d.date) - 36;
              var yPos = y(d[key]) + 16;

              if (da.id === 'ap7') {
                yPos = y(d[key]) - 49;
              }

              return 'translate(' + xPos + ', ' + yPos + ')';
            });

          d3.select('.tooltip.' + key + ' .tooltip-date')
            .html(function() {
              if(key == 'privata') {
                return '';
              } else {
                return newDate ;
              }
            })
            .attr('x', 36)
            .attr('dy', '-0.75em');

          d3.select('.tooltip.' + key + ' .tooltip-text')
            .html(function() {
                return d[key];
            })
            .attr('x', 36)
            .attr('dy', '1.35em');
        }

      });

    }

    function mouseout() {
      d3.selectAll('.tooltip, .tooltip')
        .style('display', 'none');
    }

    function init() {

      parseTime = d3.timeParse('%Y%m%d');
      bisectDate = d3.bisector(function(d) { return d.date; }).left;

      x = d3.scaleTime();
      y = d3.scaleLinear();
      z = d3.scaleOrdinal(['#0099ff', '#ffffff']);

      xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat('%Y'));

      data = d3.csvParse(ap7_chart_data, type);

      linesData = data.columns.slice(1).map(function(id) {
        return {
          id: id,
          values: data.map(function(d) {
            return {date: d.date, temperature: d[id]};
          })
        };
      });

      x.domain(d3.extent(data, function(d) { return d.date; }));

      y.domain([
        d3.min(linesData, function(c) { return d3.min(c.values, function(d) { return d.temperature; });}),
        d3.max(linesData, function(c) { return d3.max(c.values, function(d) { return d.temperature; });})
      ]);

      z.domain(linesData.map(function(c) { return c.id; }));

      line = d3.line()
        .curve(d3.curveBasis)
        .x(function(d) { return x(d.date); })
        .y(function(d) { return y(d.temperature); });

      svg = d3.select('.hero__chart');
      g = svg.append('g');

      g.append('g').classed('axis axis--x', true);

      render();
    }

    function render() {
      _updateDimensions();

      x.range([0, width]);
      y.range([height, 0]);

      g.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      g.select('.axis--x')
        .attr('transform', 'translate(0,' + height + ')')
        .call(xAxis)
        .selectAll('text')
          .attr('dy', '0.4em')
          .attr('x', function() {
            return (mq('(min-width: 768px)')) ? -25 : -20;
          })
          .attr('y', 0)
          .attr('transform', 'rotate(-90)')
          .style('text-anchor', 'end');

      // join
      gridLines = g.selectAll('.hero__chartGrid')
        .data(x.ticks());

      // enter
      gridLines
        .enter()
        .append('rect')
        .classed('hero__chartGrid', true)
        .attr('x', function(d) { return x(d); })
        .attr('width', 2)
        .attr('height', height);

      // update
      gridLines
        .attr('x', function(d) { return x(d); })
        .attr('width', 2)
        .attr('height', height);

      /**
       * Chart lines
       */
      
      // join
      funds = g.selectAll('.fund')
        .data(linesData.reverse());

      // enter
      newFunds = funds
        .enter().append('g')
          .attr('class', function(d) {
            return 'fund ' + d.id;
          });

      // var totalLength = d3.select('.line-0').node().getTotalLength();

      funds.selectAll('.line')
        .transition()
        .duration(200)
        .attr('d', function(d) { return line(d.values); })
        .attr('stroke-dasharray', 0)
        .attr('stroke-dashoffset', 0);

      newFunds.append('path')
        .attr('class', function(d, i) {
          return 'line line-' + i;
        })
        .attr('d', function(d) { return line(d.values); })
        .style('stroke', function(d) { return z(d.id); })
        .attr('stroke-dasharray', function() {
          var totalLength = this.getTotalLength();
          return totalLength + ' ' + totalLength;
        })
        .attr('stroke-dashoffset', function() {
          var totalLength = this.getTotalLength();
          return totalLength;
        })
        .transition()
          .delay(function(d, i) {
            return (i * 3000 / 3);
          })
          .duration(3000)
          .ease(d3.easeSinOut)
          .attr('stroke-dashoffset', 0);

      // join
      tooltip = g.selectAll('.tooltip')
        .data(linesData.reverse());

      // enter
      tooltipEnter = tooltip
        .enter().append('g')
          .attr('class', function(d) {
            return 'tooltip ' + d.id;
          });

      tooltipEnter.append('rect')
        .classed('tooltip-rect', true)
        .attr('width', 72)
        .attr('height', 33)
        .attr('rx', 16)
        .attr('ry', 16);

      tooltipEnter.append('text')
        .classed('tooltip-date', true)
        .style('fill', function(d, i) {
          return z(i);
        });

      tooltipEnter.append('text')
        .classed('tooltip-text', true)
        .style('fill', function(d, i) {
          return z(i);
        });

      tooltipEnter.append('circle')
        .classed('tooltip-circle', true)
        .attr('r', 9)
        .attr('cx', 36)
        .attr('cy', function(d) {
          if (d.id === 'ap7') {
            return 48;
          }
          return -16;
        })
        .style('fill', function(d, i) {
          return z(i);
        });

      overlay = g.selectAll('.overlay')
        .data([0]);


      overlay.enter()
        .append('rect')
        .attr('width', width)
        .attr('height', height)
        .classed('overlay', true)
        .on('mousemove touchmove', mousemove)
        .on('mouseout', mouseout);

      overlay
        .attr('width', width)
        .attr('height', height);


    }

    function _updateDimensions() {
      containerWidth = document.querySelector('.hero__chartContainer').clientWidth;
      containerHeight = document.querySelector('.hero__chartContainer').clientHeight;

      margin = {
        top: mq('(min-width: 768px)') ? 200 : 170,
        right: 0,
        bottom: mq('(min-width: 768px)') ? 80 : 65,
        left: 0
      };
      width = containerWidth - margin.left - margin.right;
      height = containerHeight - margin.top - margin.bottom;
    }

    return {
      init: init,
      render: render
    };
  })();

  function tooltipPosition(e) {
    
    var chart = document.getElementById('hero__chart');
    var containerWidth = document.body.clientWidth;
    var mouseX = e.pageX;

    if(mouseX < containerWidth - (containerWidth - 200)) {
      d3.select('.tooltip-date').attr('x', '70');
      d3.selectAll('.tooltip-text').attr('x', '70');
      d3.selectAll('.tooltip-rect').attr('x', '36');
    } else if(mouseX > containerWidth - 200) {
      d3.select('.tooltip-date').attr('x', '0');
      d3.selectAll('.tooltip-text').attr('x', '0');
      d3.selectAll('.tooltip-rect').attr('x', '-36');
    } else {
      d3.selectAll('.tooltip-rect').attr('x', '0');
      d3.selectAll('.tooltip-text').attr('x', '36');
    }
  }

  document.addEventListener('mousemove', function(e){
      tooltipPosition(e);
  });
  document.addEventListener('touchmove', function(e){
      tooltipPosition(e);
  });
  document.addEventListener('mouseout', function(e){
      tooltipPosition(e);
  });



  /* /hero chart */



  AP7.newPieChart = (function() {

    var parsed_data,
        itemsPerCol,
        legendsHeight,
        width,
        height,
        outerRadius,
        innerRadius,
        pie,
        arc,
        colors,
        innerText,
        legends,
        legend,
        legendEnter,
        legendOffsetY,
        legendText,
        legendTextOffset,
        legendCircle,
        legendCircleRadius,
        arcSize,
        arcSizeActive,
        g,
        svg;

    function clearInnerText() {
      d3.selectAll('.innerTextValue, .innerTextField')
        .transition()
        .duration(300)
        .style('opacity', 0);
    }

    function arcTween(outerRadius, delay) {
      return function() {
        d3.select(this).transition().delay(delay).attrTween("d", function(d) {
          var i = d3.interpolate(d.outerRadius, outerRadius);
          return function(t) { d.outerRadius = i(t); return arc(d); };
        });
      };
    }

    function swapInnerText(d, clear) {

      d3.select('.innerTextValue')
        .style('opacity', 1)
        .text(function() {
          var value = d.innehav.toString().replace('.', ',');
          return value + '%';
        });

      d3.select('.innerTextField')
        .style('opacity', 1)
        .text(function() {
          return d.bransch;
        });
    }

    function init(data) {

      parsed_data = d3.csvParse(data, function(d) {
        d.innehav = +d.innehav;
        return d;
      });

      itemsPerCol = Math.ceil(parsed_data.length / 2);
      
      pie = d3.pie()
        .sort(null)
        .value(function(d) {
          return d.innehav;
        })
        .padAngle(0); //0.02

      arc = d3.arc();

      colors = ['#002C49', '#CFD2D4', '#F3F2F2', '#2474A2', '#0099FF', '#7FCCFF', '#AEE6FE', '#CBEBFF', '#9B9B9B', '#464745'];

      svg = d3.select('.ap7__fundPieChart');

      svg.on('mouseleave', function() {
        clearInnerText();
      });

      g = svg.append('g');

      innerText = g.append('g').classed('innerText', true);

      innerText.append('text')
        .classed('innerTextValue', true)
        .attr('text-anchor', 'middle');

      innerText.append('text')
        .classed('innerTextField', true)
        .attr('text-anchor', 'middle')
        .attr('dy', '2em');

      legends = svg.append('g')
        .classed('legends', true);

      render();
    }

    function render() {
      _updateDimensions();

      arc.padRadius(outerRadius)
      arc.innerRadius(innerRadius);

      svg
        .attr('width', width)
        .attr("height", height);
      
      g.attr('transform', 'translate(' + width / 2 + ',' + outerRadius + ')');

      // join
      paths = g.selectAll("path")
        .data(pie(parsed_data));

      // enter
      paths.enter()
        .append("path")
          .each(function(d) { d.outerRadius = outerRadius - arcSizeActive; })
          .style('fill', function(d, i) {
            return colors[i];
          })
          .style('stroke', function(d, i) {
            return colors[i];
          })
          .attr('data-id', function(d, i) { return i; })
          .attr("d", arc)
          .on("mouseover", function(d) {
            swapInnerText(d.data);
            arcTween(outerRadius, 0).call(this);
          })
          .on("mouseout", function(d) {
            arcTween(outerRadius - arcSizeActive, 150).call(this);
          });

      // update
      paths
        .each(function(d) { d.outerRadius = outerRadius - arcSizeActive; })
        .attr('d', arc);



      legends
        .attr('transform', function() {
          return 'translate(0, ' + (outerRadius * 2 + 50) + ')';
        });

      // join
      legend = legends.selectAll('.legend')
        .data(parsed_data);

      // enter
      legendEnter = legend
        .enter()
        .append('g')
        .classed('legend', true)
        .attr('data-color', function(d, i) {
          return i;
        })
        .attr('transform', function(d, i) {
          if (i < itemsPerCol) {
            return 'translate(0, ' + i * legendOffsetY + ')';
          }
          i = i - itemsPerCol;
          return 'translate(' + width / 2 + ', ' + i * legendOffsetY + ')';
        });

      // update
      legend
        .attr('transform', function(d, i) {
          if (i < itemsPerCol) {
            return 'translate(0, ' + i * legendOffsetY + ')';
          }
          i = i - itemsPerCol;
          return 'translate(' + width / 2 + ', ' + i * legendOffsetY + ')';
        });

      legendText = legendEnter
        .append('text')
        .attr('x', legendTextOffset)
        .attr('y', 0)
        .text(function(d) {
          return d.bransch + ' ' + d.innehav + '%';
        })
        .on("mouseover", function(d, i) {
          var targetArc = svg.select('path[data-id="' + i + '"]').node();
          swapInnerText(d);
          arcTween(outerRadius, 0).call(targetArc);
        })
        .on('mouseout', function(d, i) {
          var targetArc = svg.select('path[data-id="' + i + '"]').node();
          arcTween(outerRadius - arcSizeActive, 150).call(targetArc);
        });

      legend.selectAll('text')
        .attr('x', legendTextOffset)
        .attr('y', 0);

      legendCircle = legendEnter
        .append('circle')
        .attr('r', legendCircleRadius)
        .attr('cx', legendCircleRadius)
        .attr('cy', legendCircleRadius * -1)
        .style('fill', function(d, i) {
          return colors[i];
        });

      legend.selectAll('circle')
        .attr('r', legendCircleRadius)
        .attr('cx', legendCircleRadius)
        .attr('cy', legendCircleRadius * -1);


    }

    function _updateDimensions() {
      itemsPerCol = Math.ceil(parsed_data.length / 2);
      legendOffsetY = mq('(min-width: 1100px)') ? 32 : 28;
      legendsHeight = itemsPerCol * legendOffsetY;

      width = document.querySelector('.ap7__fundPieChartContainer').clientWidth;
      height = mq('(min-width: 1100px)') ? (width * 0.66 + legendsHeight) : (width + legendsHeight);

      arcSize = mq('(min-width: 1100px)') ? 55 : 30;
      arcSizeActive = 20;
      legendCircleRadius = mq('(min-width: 1100px)') ? 6 : 4;
      legendTextOffset = (legendCircleRadius * 2) * 2;

      outerRadius = mq('(min-width: 1100px)') ? width * 0.33 - arcSizeActive : width * 0.5 - arcSizeActive;
      innerRadius = outerRadius - arcSize - arcSizeActive;
    }

    return {
      init: init,
      render: render
    };

  })();



  AP7.pieChart = function(data) {

    var parsed_data = d3.csvParse(data, function(d) {
      d.innehav = +d.innehav;
      return d;
    });

    var itemsPerCol = Math.ceil(parsed_data.length / 2);

    var chartSelector = '.ap7__fundPieChart',
        chartElem = document.querySelector(chartSelector),
        legendsHeight = itemsPerCol * 32,
        width = chartElem.clientWidth,
        height = (width * 0.66) + legendsHeight;

    var outerRadius = width * 0.33 - 20,
        innerRadius = outerRadius - 55 - 20;
 
    var pie = d3.pie()
        .sort(null)
        .value(function(d) {
          return d.innehav;
        })
        .padAngle(0); //0.02

    var arc = d3.arc()
        .padRadius(outerRadius)
        .innerRadius(innerRadius);

    var colors = ['#002C49', '#CFD2D4', '#F3F2F2', '#2474A2', '#0099FF', '#7FCCFF', '#AEE6FE', '#CBEBFF', '#9B9B9B', '#464745'];

    function clearInnerText() {
      d3.selectAll('.innerTextValue, .innerTextField')
        .transition()
        .duration(300)
        .style('opacity', 0);
    }

    var svg = d3.select(chartSelector).append("svg")
        .attr("width", width)
        .attr("height", height)
        .on('mouseleave', function() {
          clearInnerText();
        })
      .append("g")
        .attr("transform", "translate(" + width / 2 + "," + outerRadius + ")");

    function arcTween(outerRadius, delay) {
      return function() {
        d3.select(this).transition().delay(delay).attrTween("d", function(d) {
          var i = d3.interpolate(d.outerRadius, outerRadius);
          return function(t) { d.outerRadius = i(t); return arc(d); };
        });
      };
    }

    function swapInnerText(d, clear) {

      d3.select('.innerTextValue')
        .style('opacity', 1)
        .text(function() {
          var value = d.innehav.toString().replace('.', ',');
          return value + '%';
        });

      d3.select('.innerTextField')
        .style('opacity', 1)
        .text(function() {
          return d.bransch;
        });
    }

    svg.selectAll("path")
        .data(pie(parsed_data))
      .enter().append("path")
        .each(function(d) { d.outerRadius = outerRadius - 20; })
        .style('fill', function(d, i) {
          return colors[i];
        })
        .style('stroke', function(d, i) {
          return colors[i];
        })
        .attr('data-id', function(d, i) { return i; })
        .attr("d", arc)
        .on("mouseover", function(d) {
          swapInnerText(d.data);
          arcTween(outerRadius, 0).call(this);
        })
        .on("mouseout", function(d) {
          arcTween(outerRadius - 20, 150).call(this);
        });

    var innerText = svg.append('g').classed('innerText', true);

    innerText.append('text')
      .classed('innerTextValue', true)
      .attr('text-anchor', 'middle');

    innerText.append('text')
      .classed('innerTextField', true)
      .attr('text-anchor', 'middle')
      .attr('dy', '2em');

    var legends = d3.select('.ap7__fundPieChart svg').append('g')
      .classed('legends', true)
      .attr('transform', function() {
        return 'translate(0, ' + (outerRadius * 2 + 50) + ')';
      });

    var legend = legends.selectAll('.legend')
      .data(parsed_data)
      .enter().append('g')
      .attr('transform', function(d, i) {
        if (i < itemsPerCol) {
          return 'translate(0, ' + i * 32 + ')';
        }
        i = i - itemsPerCol;
        return 'translate(' + width / 2 + ', ' + i * 32 + ')';
      });


    legend
      .append('text')
      .text(function(d) {
        return d.bransch + ' ' + d.innehav + '%';
      })
      .attr('x', 24)
      .attr('y', 0)
      .on("mouseover", function(d, i) {
        var targetArc = svg.select('path[data-id="' + i + '"]').node();
        swapInnerText(d);
        arcTween(outerRadius, 0).call(targetArc);
      })
      .on('mouseout', function(d, i) {
        var targetArc = svg.select('path[data-id="' + i + '"]').node();
        arcTween(outerRadius - 20, 150).call(targetArc);
      });

    legend.append('circle')
      .attr('r', 6)
      .attr('cx', 6)
      .attr('cy', -7);


  };







  AP7.fundList = function() {

    if (!document.querySelector('.ap7__fundListing')) {
      return false;
    }

    var fundListOptions = {
      valueNames: ['title', 'marketvalue', 'share', 'position'],
      page: 20
    };
    var fundList = new List('ap7__fundListingContainer', fundListOptions);
    var showAllButton = document.querySelector('.ap7__fundListingShowAll');

    fundList.on('searchComplete', function() {
      if (fundList.searched) {
        showAllButton.disabled = true;
      } else {
        showAllButton.disabled = false;
      }
    });

    showAllButton.addEventListener('click', function(e) {
      e.preventDefault();
      fundList.page = fundList.size();
      fundList.update();
      showAllButton.disabled = true;
    });
  };






  AP7.documentList = function() {

    if (!document.querySelector('.ap7__documentListing')) {
      return false;
    }

    var documentListOptions = {
      valueNames: [
        'filename',
        'filedate',
        // 'filetype',
        {data: ['term']}
      ],
      page: 100
    };

    var documentList = new List('ap7__documentListingContainer', documentListOptions);

    var filter = document.querySelectorAll('.ap7__documentListingFilter');

    function filterUnique() {
      documentList.filter(function(item) {
        return documentList.items.findIndex(function(item2) {return item2.values().filename === item.values().filename}) === documentList.items.indexOf(item);
      })
    }

    filterUnique();

    for (var i = 0; i < filter.length; i++) {
      filter[i].addEventListener('click', function(e) {

        e.preventDefault();
        var that = this;

        if (that.textContent === 'Visa alla') {
          filterUnique();
        } else {
          documentList.filter(function(item) {
            if (item.values().term === that.textContent) {
              return true;
            }
          });
        }

        

        for (var i = 0; i < filter.length; i++) {
          filter[i].classList.remove('active');
        }

        this.classList.toggle('active');

      }); // filter
    } // for

  };



  AP7.stickyNav = function() {
    var headerElem = document.querySelector('.ap7__header');
    var headroom  = new Headroom(headerElem, {
      offset : document.querySelector('.main').offsetTop,
      tolerance : 20,
      classes : {
        initial : 'ap7__header',
        pinned : 'ap7__header--pinned',
        unpinned : 'ap7__header--unpinned',
        top : 'ap7__header--top',
        notTop : 'ap7__header--not-top',
        bottom : 'ap7__header--bottom',
        notBottom : 'ap7__header--not-bottom'
      },
      onPin : function() {
        jQuery('.ap7__sideBar').css('margin-top', '181px');
      },
      onUnpin : function() {
        jQuery('.ap7__sideBar').css('margin-top', '0');
      },

    });

    headroom.init();
  };

  AP7.resize = function(){

    function resize() {

      jQuery('.ap7__sideBar').removeAttr('style');
      jQuery('.ap7__sideBar').css('position','static');
      var sidebarWidth = jQuery('.ap7__sideBar').width();
      jQuery('.ap7__sideBar').css('position','');
      jQuery('.ap7__sideBar').width( sidebarWidth );

      var rightToCanv = (jQuery(document).width() - jQuery('#contentMain').width()) / 2;
      jQuery('.ap7__sideBar').css('right', rightToCanv);

      AP7.stickySideBar();
    }
    jQuery(window).on("resize", resize);
 

  }


  AP7.stickySideBar = function() {



    var sidebarElem = document.querySelector('.ap7__sideBar');
    var rightToCanv = jQuery(document).width() - (jQuery('.ap7__sideBar').offset().left + jQuery('.ap7__sideBar').outerWidth());
    var sidebarWidth = jQuery('.ap7__sideBar').width();
    jQuery('.ap7__sideBar').css('right', rightToCanv+'px');
    jQuery('.ap7__sideBar').css('width', sidebarWidth+'px');


    var offsetStart = document.querySelector('.main').offsetTop;
    offsetStart = offsetStart - 100;
    console.log(offsetStart);
    var headroom  = new Headroom(sidebarElem, {
      offset : offsetStart,
      tolerance : 20,
      classes : {
        initial : 'ap7__sideBar',
        pinned : 'ap7__sideBar--pinned',
        unpinned : 'ap7__sideBar--unpinned',
        top : 'ap7__sideBar--top',
        notTop : 'ap7__sideBar--not-top',
        bottom : 'ap7__sideBar--bottom',
        notBottom : 'ap7__sideBar--not-bottom'
      },
      onNotTop : function() {
         rightToCanv = jQuery(document).width() - (jQuery('.ap7__sideBar').offset().left + jQuery('.ap7__sideBar').outerWidth());
         //sidebarWidth = jQuery('.ap7__sideBar').width();
         if(!!navigator.userAgent.match(/Trident.*rv\:11\./))
          rightToCanv = 0;
        
        jQuery('.ap7__sideBar').css('right', rightToCanv+'px');
        jQuery('.ap7__sideBar').css('width', sidebarWidth+'px');
      },
      onTop : function() {
        rightToCanv = jQuery(document).width() - (jQuery('.ap7__sideBar').offset().left + jQuery('.ap7__sideBar').outerWidth());
        //sidebarWidth = jQuery('.ap7__sideBar').width();
        if(!!navigator.userAgent.match(/Trident.*rv\:11\./))
          rightToCanv = 0;
       jQuery('.ap7__sideBar').css('right', rightToCanv+'px');
       jQuery('.ap7__sideBar').css('width', sidebarWidth+'px');
       jQuery('.ap7__sideBar').css('margin-top', '0');
      },

    });

    headroom.init();

    var orgStyle = jQuery('.ap7__sideBar').attr('style');

    jQuery(window).scroll(function(){

      if(jQuery('.ap7__sideBar--not-top').length && (jQuery(window).scrollTop() - (jQuery('footer').offset().top) > 0))
      {
        var scrollTop = jQuery(window).scrollTop() - (jQuery('footer').offset().top);
        var transString = 'translateY(' + -scrollTop + 'px)';
        //jQuery('.ap7__sideBar').css('transform',transString);
        jQuery('.ap7__sideBar').css('margin-top','0px');
        jQuery('.ap7__sideBar').css('z-index','0');
        
        jQuery('.ap7__sideBar').addClass('docked-bottom');
        jQuery('#contentMain').css('position', 'relative');
      }
      else
      {
        var mTop = (jQuery('.ap7__header--pinned').length)? '121px' : '0';
        if(jQuery('.ap7__header--top').length && jQuery('.ap7__sideBar--top').length)
          mTop = 0;

        jQuery('.ap7__sideBar').css('margin-top',mTop);
        jQuery('.ap7__sideBar').css('transform','');
        jQuery('.ap7__sideBar').css('z-index','0');

        jQuery('.ap7__sideBar').removeClass('docked-bottom');
        
        jQuery('#contentMain').removeAttr('style');
      }
    });


  };



  AP7.stickyArchiveHeader= function() {
    var sidebarElem = document.querySelector('.ap7__archiveHeader');
    var offsetStart = document.querySelector('.main').offsetTop;
    offsetStart = offsetStart - 100;
    var headroom  = new Headroom(sidebarElem, {
      offset : offsetStart,
      tolerance : 0,
      classes : {
        initial : 'ap7__archiveHeader',
        pinned : 'ap7__archiveHeader--pinned',
        unpinned : 'ap7__archiveHeader--unpinned',
        top : 'ap7__archiveHeader--top',
        notTop : 'ap7__archiveHeader--not-top',
        bottom : 'ap7__archiveHeader--bottom',
        notBottom : 'ap7__archiveHeader--not-bottom'
      }
    });

    headroom.init();
  };

  AP7.stickyMobileNav = function() {
    var navElem = document.querySelector('.ap7__mobileNavigationButton');
    var headroom  = new Headroom(navElem, {
      offset : 20,
      tolerance : 20,
      classes : {
        initial : 'ap7__mobileNavigationButton',
        pinned : 'ap7__mobileNavigationButton--pinned',
        unpinned : 'ap7__mobileNavigationButton--unpinned',
        top : 'ap7__mobileNavigationButton--top',
        notTop : 'ap7__mobileNavigationButton--not-top',
        bottom : 'ap7__mobileNavigationButton--bottom',
        notBottom : 'ap7__mobileNavigationButton--not-bottom'
      },
      // callback when pinned, `this` is headroom object
      onPin : function() {
        TweenMax.to(this.elem, 0.4, {opacity: 1, ease: Power2.easeOut, onComplete: function() {
          TweenMax.set(this.target, {css: {'pointer-events': 'auto'}});
        }});
      },
      // callback when unpinned, `this` is headroom object
      onUnpin : function() {
        TweenMax.to(this.elem, 0.4, {opacity: 0, ease: Power2.easeOut, onComplete: function() {
          TweenMax.set(this.target, {css: {'pointer-events': 'none'}});
        }});
      }
    });

    headroom.init();
  };

  AP7.mobileNavButton = function() {
    var button = document.querySelector('.ap7__mobileNavigationButton');
    var overlay = document.querySelector('.ap7__mobileNavigationOverlay');
    var list = document.querySelector('.ap7__mobileNavigationList');
    var container = jQuery('.menu-huvudmeny-container');
    // var header = document.querySelector('.ap7__header');
    var timeline = new TimelineMax();
    var buttonTimeline = new TimelineMax();
    
    button.setAttribute("tabIndex", "2");
    

  
    
    function bumpAnimation() {
      buttonTimeline
        .to(button, 0.1, {scale: 0.9})
        .to(button, 0.1, {scale: 1});
    }

    button.addEventListener('click', function(e) {

      if (overlay.classList.contains('ap7__mobileNavigationOverlay--is-open')) {

        bumpAnimation();

        overlay.classList.remove('ap7__mobileNavigationOverlay--is-open');
        button.classList.remove('ap7__mobileNavigationButton--open');
        document.body.classList.remove('noscroll');

        TweenMax.to(overlay, 0.4, {backgroundColor: 'rgba(0, 44, 73, 0)', ease: Power2.easeOut});
        TweenMax.to(list, 0.4, {opacity: 0, ease: Power2.easeOut});
        // TweenMax.to(siteLogoMobile, 0.4, {opacity: 0, ease: Power2.easeOut});

      } else {

        bumpAnimation();

        overlay.classList.add('ap7__mobileNavigationOverlay--is-open');
        button.classList.add('ap7__mobileNavigationButton--open');
        document.body.classList.add('noscroll');

        TweenMax.to(overlay, 0.4, {backgroundColor: 'rgba(0, 44, 73, 1)', ease: Power2.easeOut});
        TweenMax.to(list, 0.4, {opacity: 1, ease: Power2.easeOut});
        setTimeout(function(){ jQuery(container).find('a:first').focus(); }, 1000);
        

        // TweenMax.to(siteLogoMobile, 0.4, {opacity: 1, ease: Power2.easeOut});
      }
      
    });
    
  };


  AP7.mobileNavMore = function() {
    var moreButtons = document.querySelectorAll('.ap7__mobileNavigationListMore');

    for (var i = 0; i < moreButtons.length; i++) {
      moreButtons[i].addEventListener('click', function(e) {
        e.preventDefault();
        this.parentNode.classList.toggle('active');
      });
    }
  };



  AP7.searchButton = function() {
    var searchButton = document.querySelector('.ap7__headerSearch');
    var searchOverlay = document.querySelector('.ap7__searchOverlay');
    var searchField = searchOverlay.querySelector('.search-field');
    var isOpen = false;

    if (!searchButton){
      return false;
    }
    searchButton.addEventListener('click', function(e) {

      document.body.classList.toggle('noscroll');
      document.body.classList.toggle('ap7__searchOverlay--open');
      searchButton.classList.toggle('ap7__headerSearch--open');

      if (isOpen) {
        TweenMax.to(searchOverlay, 0.4, {opacity: 0, ease: Power2.easeOut});
 

      } else {
        TweenMax.to(searchOverlay, 0.4, {opacity: 1, ease: Power2.easeOut, onComplete: function() {
         
            searchField.addEventListener('keydown', function(e) {
  
              if(e.key === "Escape") {
      
                document.body.classList.remove('noscroll');
                document.body.classList.remove('ap7__searchOverlay--open');
                searchButton.classList.remove('ap7__headerSearch--open');
      
                TweenMax.to(searchOverlay, 0.4, {opacity: 0, ease: Power2.easeOut});

                setTimeout(function() {
                  searchButton.focus();
                }, 2);
                isOpen = false;
              }else if(e.key === "Tab" ){
                e.preventDefault();
                setTimeout(function() {
                  searchButton.focus();
                }, 20);
              }
        
            }, false);

          setTimeout(function() {
            searchField.focus();
          }, 200);

        }});
      }

      isOpen = !isOpen;
    });
  };



  AP7.filterList = function() {
    var filterButtons = document.querySelectorAll('.ap7__startblockPosts [data-filter]');

    for (var i = 0; i < filterButtons.length; i++) {
      filterButtons[i].addEventListener('click', function(e) {
        e.preventDefault();

        var filter = this.getAttribute('data-filter');
        var groups = this.parentNode.parentNode.parentNode.parentNode.querySelectorAll('[data-group]');
        var that = this;

        for (var j = 0; j < filterButtons.length; j++) {
          if (this === filterButtons[j]) {
            filterButtons[j].classList.add('ap7__activeFilter');  
          } else {
            filterButtons[j].classList.remove('ap7__activeFilter');
          }
        }

        for (var k = 0; k < groups.length; k++) {
          var group = groups[k].getAttribute('data-group');

          if (group === filter) {
            groups[k].style.display = 'block';
          } else {
            groups[k].style.display = 'none';
          }
        }
      });
    }
  };


  AP7.videoHandler = function() {
    var videoButtons = document.querySelectorAll('.ap7__startblockPlayButton');

    for (var i = 0; i < videoButtons.length; i++) {
      videoButtons[i].addEventListener('click', function(e) {
        e.preventDefault();
        var videoId = this.getAttribute('data-id');
        var videoMarkup = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + videoId + '?autoplay=1&rel=0" frameborder="0" allowfullscreen></iframe>';
        var videoBlock = this.parentNode.parentNode.parentNode.parentNode.parentNode;

        videoBlock.classList.add('ap7__startblock--video169');
        videoBlock.innerHTML = videoMarkup;
      });
    }

  };


  AP7.addFeatureClasses = function() {
    var style = document.createElement('a').style;
    style.cssText = 'pointer-events:auto';

    if (style.pointerEvents === '') {
      var html = document.getElementsByTagName('html')[0];
      html.classList.add('no-csspointerevents');
    }
  };

  AP7.distributionSlider = function() {

    var sliderData = [
      {
          "age": "55 år",
          "eqf": "100%",
          "fif": "0%",
          "risk": 6,
          "avgift": "0,05%"
      },
      {
          "age": "56 år",
          "eqf": "97%",
          "fif": "3%",
          "risk": 6,
          "avgift": "0,05%"
      },
      {
          "age": "57 år",
          "eqf": "93%",
          "fif": "7%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "58 år",
          "eqf": "90%",
          "fif": "10%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "59 år",
          "eqf": "87%",
          "fif": "13%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "60 år",
          "eqf": "83%",
          "fif": "17%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "61 år",
          "eqf": "80%",
          "fif": "20%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "62 år",
          "eqf": "77%",
          "fif": "23%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "63 år",
          "eqf": "73%",
          "fif": "27%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "64 år",
          "eqf": "70%",
          "fif": "30%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "65 år",
          "eqf": "67%",
          "fif": "33%",
          "risk": 5,
          "avgift": "0,05%"
      },
      {
          "age": "66 år",
          "eqf": "63%",
          "fif": "37%",
          "risk": 4,
          "avgift": "0,05%"
      },
      {
          "age": "67 år",
          "eqf": "60%",
          "fif": "40%",
          "risk": 4,
          "avgift": "0,05%"
      },
      {
          "age": "68 år",
          "eqf": "57%",
          "fif": "43%",
          "risk": 4,
          "avgift": "0,05%"
      },
      {
          "age": "69 år",
          "eqf": "53%",
          "fif": "47%",
          "risk": 4,
          "avgift": "0,05%"
      },
      {
          "age": "70 år",
          "eqf": "50%",
          "fif": "50%",
          "risk": 4,
          "avgift": "0,05%"
      },
      {
          "age": "71 år",
          "eqf": "47%",
          "fif": "53%",
          "risk": 4,
          "avgift": "0,04%"
      },
      {
          "age": "72 år",
          "eqf": "43%",
          "fif": "57%",
          "risk": 4,
          "avgift": "0,04%"
      },
      {
          "age": "73 år",
          "eqf": "40%",
          "fif": "60%",
          "risk": 4,
          "avgift": "0,04%"
      },
      {
          "age": "74 år",
          "eqf": "37%",
          "fif": "63%",
          "risk": 4,
          "avgift": "0,04%"
      },
      {
          "age": "75 år",
          "eqf": "33%",
          "fif": "67%",
          "risk": 4,
          "avgift": "0,04%"
      }
  ];

    if ($('#slider').length > 0) {

        var Slider = {
            initial: 0,
            lastKey: 5,
            lastItem: Object.keys(sliderData).length - 1,
        };

        function updateLabels(key) {
          var ageData = sliderData[key];
          var cleanAge = ageData.age.match(/\d+/g).map(Number)[0];
          $('.ui-slider-handle').attr('aria-valuenow', cleanAge);
          $("#slider-value").text(key);
          ['age', 'eqf', 'fif', 'risk', 'avgift'].forEach(function(n) {
            $('#slider-value-' + n).text(ageData[n])    
          });
        };

        $("#slider").slider({
            value: Slider.initial,
            min: 0,
            max: 20,
            step: 1,
            create: function(event, ui) {
                updateLabels(Slider.initial);
                $('#slider-value-age').appendTo($('#slider span'));
                $('.meter-bar').css("width", sliderData[Slider.initial].eqf);
                $('.ui-slider-handle').attr('aria-label', 'Ställ in ålder');
                $('.ui-slider-handle').attr('aria-role', 'slider');
                $('.ui-slider-handle').attr('aria-valuemin', '55');
                $('.ui-slider-handle').attr('aria-valuemax', '75');
            },
            slide: function(event, ui) {
                var key = Math.max(0, Math.round(ui.value));


                if (Slider.lastKey != key) {
                    Slider.lastKey = key;
                    updateLabels(key);
                    $('.meter-bar').css("width", sliderData[key].eqf);
                }

                // adjust age label position when on the first / last items
                if (key === 0) { // first item
                    $('#slider-value-age').removeClass();
                    $('#slider-value-age').addClass('start-pos-0');
                } else if (key === 1){ // second item
                    $('#slider-value-age').removeClass();
                    $('#slider-value-age').addClass('start-pos-1');
                } else if (Slider.lastItem === key) { // last item
                    $('#slider-value-age').removeClass();
                    $('#slider-value-age').addClass('end-pos-0');
                } else if (Slider.lastItem - 1 === key) { // last but one item
                    $('#slider-value-age').removeClass();
                    $('#slider-value-age').addClass('end-pos-1');
                } else { // make sure to remove the pos class if key is not any of the above
                    $('#slider-value-age').removeClass();
                }

            },
            start: function(event, ui) {
              $('#slider-value-avgift').addClass('temp-change');
              $('#slider-value-risk').addClass('temp-change');
            },
            stop: function(event, ui) {
              // setTimeout(function(){
                $('#slider-value-avgift').removeClass('temp-change');
                $('#slider-value-risk').removeClass('temp-change');
              // }, 2000);
            }
        });
    }

  }


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = AP7;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  //SMOOTH SCROLL TO ANCHORLINKS

  setTimeout(function() {
    if (location.hash) {
      window.scrollTo(0, 0);
      target = location.hash.split('#');
      if(target[1].indexOf('!/') > -1){
        smoothScrollTo($('#'+'view'));
      } else {
        smoothScrollTo($('#'+target[1]));
      }
    }
  }, 1);
  
  $('a[href^="#"]').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      smoothScrollTo($(this.hash));
      return false;
    }
  });
  
  function smoothScrollTo(target) {
    target = target.length ? target : $('[id=' + this.hash.slice(1) +']');
    if (target.length) {
      $('html,body').animate({
        scrollTop: target.offset().top
      }, 1000);
    }
  }

  $('.share-twitter-link').click(function(e) {
    e.preventDefault();
    var href = $(this).attr('href');
    window.open(href, "Twitter", "height=285,width=550,resizable=1");
  });
  $('.share-facebook-link').click(function(e) {
    e.preventDefault();
    var href = $(this).attr('href');
    window.open(href, "Facebook", "height=269,width=550,resizable=1");
  });




  function validate() {

      var email = $("#es_txt_email_pg").val();

      $("#checkbox").on('click', function() {
          if($("#checkbox").is(":checked") && validateEmail(email)){
              $('.es_submit_button').attr('disabled', false);
          } else {
              $('.es_submit_button').attr('disabled',true);
          }
      });

      if( validateEmail(email) && $("#checkbox").is(":checked")) { 
        //$('.es_submit_button, .wpcf7-submit').attr('disabled', false);
      } else if(!validateEmail(email)) {
        //$('.es_submit_button, .wpcf7-submit').attr('disabled', true);
      }

      return false;
  }

  $("#es_txt_email_pg").bind("keyup", validate);

  function validateEmail(emailAddress) {

      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      
      return pattern.test(emailAddress);
  }

  // $('.es_button').on('click', function() {

  //   $(this).hide();
  //   $('.checkbox').hide();

  //   var $msg = $('#es_msg_pg');
  //   $msg.removeClass('verify');

  //   $('.es_msg').addClass("loading loading-icon");

  //   var verify = function() {
  //       $('.es_msg').removeClass("loading loading-icon");
  //       $('.es_button, .checkbox').show();
  //       $msg.addClass('verify');
  //       $msg.html(function () {
  //           if ($(this).html() == "Prenumeration lyckades") {
  //               return $(this).html().replace("Prenumeration lyckades", "Tack, du är nu anmäld!");
  //           } else if ($(this).html() == "E-postadressen finns redan") {
  //               return $(this).html().replace("E-postadressen finns redan", "E-postadressen finns redan!"); 
  //           } else if ($(this).html() == "Ogiltig e-postadress") {
  //               return $(this).html().replace("Ogiltig e-postadress", "Något gick snett, försök igen senare!"); 
  //           }
  //       });
  //   };

  //  $msg.bind("DOMSubtreeModified", verify);

  // });

  $( "input.yikes-easy-mc-email " ).focus(function() {
    $(this).siblings("span").first().addClass('focused');
  });

  $( "input.yikes-easy-mc-email " ).blur(function() {
   if ($(this).val().length) {
     return false;
   }
    $(this).siblings("span").first().removeClass('focused');
  });

  const expandArchive = function (el, arrow) {

    el.slideToggle('slow');
    arrow.toggleClass('expanded');

  }

  const closeArchive = function (el, arrow) {

    el.slideUp('slow');
    //arrow.removeClass('expanded');

  }

  //ARCHIVE EXPAND
  // $('.ap7__sideBar .ap7__archiveHeader').click(function() {
  //   const arrow = $(this).children('.ap7__archiveIcon');
  //   expandArchive($('.ap7__archiveListSidebar'), arrow);
  // })

  $('.ap7__sideBar .ap7__archiveHeader').on('click keydown',function(e) {
    
    if(e.type == "keydown") {
      var isShift;
      isShift = !!e.shiftKey;
      switch(e.key){
          case 'Enter': 
          console.log('Enter');
            const arrow = $(this).children('.ap7__archiveIcon');
            expandArchive($('.ap7__archiveListSidebar'), arrow);      
            break;
          case 'Tab':
            if(!isShift){
              console.log('tab on header');
              $('.ap7__sideBar .ap7__archiveListSidebar .ap7__archiveListYearBlock:first h3.ap7__archiveListYear:first').attr('tabindex', '0').focus();
                            
            }else{

            }

            break;
      }  
    } else{
      const arrow = $(this).children('.ap7__archiveIcon');
      expandArchive($('.ap7__archiveListSidebar'), arrow);    
      jQuery('.latest-posts-widget').toggleClass('faded');
      jQuery('#contentMain').toggleClass('extended');
    }



    

  })

  $('.ap7__sideBar .ap7__archiveListYear').on('click keydown',function(e) {

    if(e.type == "keydown") {
      var isShift;
      isShift = !!e.shiftKey;
      switch(e.key){
          case 'Enter': 
          console.log('Enter on year');
            const monthList = $(this).siblings().closest('ul');
            const arrow = $(this).children('.ap7__archiveIcon');
            expandArchive(monthList, arrow);
            break;
          case 'Tab':
            if(isShift){
              jQuery(this).closest('.ap7__archiveListYearBlock').find('h3.ap7__archiveListYear').focus();
            }else{
              jQuery(this).closest('.ap7__archiveListYearBlock').find('h3.ap7__archiveListYear').focus();
            }            
            break;
      }

    }else{
      const monthList = $(this).siblings().closest('ul');
      const arrow = $(this).children('.ap7__archiveIcon');
      console.log($(this).parent().siblings().find('ul'));
      const othersList = $(this).parent().siblings().find('ul');

      
      expandArchive(monthList, arrow);    
      closeArchive(othersList, arrow);    
    }

  })



  //KAMPANJ REMOVE WHEN DONE

  $('.landingPageTemplate').on( 'scroll', function() { 
    setTimeout(function(){ $('.btn--hidden').removeClass('hidden');}, 1500); 
  });

  $(document).on( 'click', '.closeHeader', function() { 
    $('.ap7__headerInfo').hide();
    $('.ap7__header').removeClass('hasHeaderInfo');
  });

})(jQuery); // Fully reference jQuery after this point.


